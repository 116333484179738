import React from 'react';
import { StyledCopyrightContainer } from 'components/common/Copyright/styles';
import Emoji from 'components/common/Emoji';
import { strings } from 'const/strings';

const Copyright = () => {
  return (
    <StyledCopyrightContainer>
      <div>{strings.FOOTER.COPYRIGHT}</div>
      <div>
        {strings.FOOTER.MADE_WITH}
        <Emoji symbol="❤️" label="red-heart" />
        {strings.FOOTER.ALL_OVER_THE}
        <Emoji symbol="🌎" label="globe" />
      </div>
    </StyledCopyrightContainer>
  );
};

export default Copyright;
